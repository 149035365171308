import log from "loglevel";
import { noCompany } from './../utils';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { Auth } from "aws-amplify";
import { AppState } from "../store";
import { RequestStatus } from "./enums";
import { Permission, Role, rolePermissions } from '../model/security';

export enum CognitoUserAttrs {
  email = 'email',
  given_name = 'given_name',
  family_name = 'family_name',
  company = 'custom:company',
  phone_number = 'phone_number',
  companyProvided = 'custom:companyProvided',
  role = 'custom:role',
  preferred_username = 'preferred_username'
}

export interface User {
  userId: string,
  email: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
  accessToken: string;
  idToken: string;
  company: string;
  companyProvided: string;
  role: string;
  preferred_username: string;
}

export interface SignInCredentials {
  email: string;
  password: string;
}

export interface AuthStateSlice {
  user: User | null
  companyId: string
  role: Role
  permissions: Permission[]
  status: RequestStatus
  error: string | null
}

const initialState: AuthStateSlice = {
  user: null,
  companyId: noCompany.companyId,
  role: Role.NONE,
  permissions: [],
  status: RequestStatus.idle,
  error: null
};

export const signInUser = createAsyncThunk(
  "signin",
  async (params: SignInCredentials | undefined, thunkAPI) => {
    var response;
    const signInLocal = (params === undefined) ? true : false
    try {
      // If called without params it means we want to check with the current token in local store.
      // This happens when a protected route in entered directly (bookmark).
      if (signInLocal) {
        log.debug('SignIn: requested (local).')
        response = await Auth.currentAuthenticatedUser();
      } else {
        log.debug('SignIn: requested (credentials).')
        response = await Auth.signIn({
          username: params?.email as string,
          password: params?.password as string,
        });
      }
    }
    catch (err) {
      // Do not report an error, this is from Auth.currentAuthenticatedUser(), it be handled
      // as an failed authentication with a redirect to the login page, we don't want to display 
      // an error since the user has just been redirected to the sign in page.
      if (signInLocal) {
        thunkAPI.rejectWithValue("")
      }
      else {
        return (err instanceof Error) ? thunkAPI.rejectWithValue(err.message) :
          thunkAPI.rejectWithValue(JSON.stringify(err))
      }
    }

    return {
      sigInLocal: signInLocal,
      attributes: response.attributes,
      idToken: response.signInUserSession.idToken.jwtToken,
      accessToken: response.signInUserSession.accessToken.jwtToken
    };
  }
);

export const signOutUser = createAsyncThunk("sigout", async (thunkAPI) => {
  await Auth.signOut();
});

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    companySet: (state: any, action: PayloadAction<string>) => {
      state.companyId = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(signInUser.pending, (state, action) => {
        state.status = RequestStatus.loading;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        log.debug('SignIn: fulfilled.')
        state.status = RequestStatus.succeeded;
        state.user = {
          email: action.payload.attributes.email,
          userId: action.payload.attributes.userId,
          familyName: action.payload.attributes.family_name,
          givenName: action.payload.attributes.given_name,
          phoneNumber: action.payload.attributes.phone_number,
          company: action.payload.attributes[CognitoUserAttrs.company] || noCompany.companyId,
          preferred_username: action.payload.attributes[CognitoUserAttrs.preferred_username] || noCompany.companyId,
          companyProvided: action.payload.attributes[CognitoUserAttrs.companyProvided],
          role: action.payload.attributes[CognitoUserAttrs.role],
          idToken: action.payload.idToken,
          accessToken: action.payload.accessToken,
        }

        // Only update the role and company if it is a real login, for a "refresh" login
        // keep existing values. We don't want to delete companyId in case od sysadmin.
        if (!action.payload.sigInLocal) {
          state.role = action.payload.attributes[CognitoUserAttrs.role] as Role;
          state.permissions = rolePermissions[state.role]
          state.companyId = (state.role === Role.SYSADMIN) ?
            noCompany.companyId : action.payload.attributes[CognitoUserAttrs.company] ?? noCompany.companyId
        }
      })
      .addCase(signInUser.rejected, (state, action) => {
        log.debug('SignIn: failed.')
        state.status = RequestStatus.failed
        state.error = action.payload as string
      })
      .addCase(signOutUser.fulfilled, (state, action) => {
        state.user = null
        state.companyId = noCompany.companyId
        state.role = Role.NONE
        state.status = RequestStatus.idle
      });
  },
});

export const selectIdToken = (state: AppState) => {
  return state.auth.user?.idToken ?? ""
};

export const selectAccessToken = (state: AppState) => {
  return state.auth.user?.accessToken;
};

export const selectCurrentUser = (state: AppState) => {
  return state.auth.user;
};

export const selectCompanyId = (state: AppState) => {
  return state.auth.companyId;
};

export const selectUserRole = (state: AppState) => {
  return state.auth.role;
};

export const selectCurrentAuth = (state: AppState) => {
  return state.auth;
};

export const { companySet } = authSlice.actions

export default authSlice.reducer;