import { MetricRecord } from "../../services/CloudWatchClient";

export type Aggregation = {
  label: string;
  value: number;
};

export const aggregationPeriods = [
  { label: "1 min", value: 1 },
  { label: "5 min", value: 5 },
  { label: "15 min", value: 15 },
  { label: "1 hour", value: 60 },
  { label: "1 day", value: 1440 },
];

interface MetricDefinition {
  [index:string]: MetricDef
}
export interface MetricDef {
  name: string,
  label: string,
  normFn: any
}
 
export const metricDefinitions : MetricDefinition= {
  "ProgramTime" : {
    name: "Program Duration",
    label: "Duration (Minutes)",
    normFn: (record: MetricRecord) => (
      {
        time: record?.time, 
        value: Math.round( ((record?.value ?? 0)/60)*100 )/100
      })
  },
  "ProgramCycle" : {
    name: "Program Cycles",
    label: "Cycles Count",
    normFn: (record: any) => record
  },
  "ProgramLoopTime": {
    name: "Program Loop Duration",
    label: "Duration (Minutes)",
    normFn: (record: MetricRecord) => (
      {
        time: record?.time, 
        value: Math.round( ((record?.value ?? 0)/60)*100 )/100
      })
  },
  "ProgramLoopCycle": {
    name: "Program Loop Cycles",
    label: "Cycles Count",
    normFn: (record: any) => record
  },
  // For Parts
  "ServiceHours" : {
    name: "Running Hours",
    label: "Hours",
    normFn: (record: MetricRecord) => (
      {
        time: record?.time, 
        value: Math.round( ((record?.value ?? 0)/3600)*100 )/100
      })
  }
}

export const getMetricDefinition = (metricName: string | null) : MetricDef => {
  const noDef: MetricDef = {name: "No Metric", label: "No Label", normFn: (i: any) => (i)}

  if (metricName === null) {
    return noDef
  }
  else {
    const def = metricDefinitions[metricName]
    return (def !== undefined) ? def : noDef
  }
}
