import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CloudWatchClient from "../services/CloudWatchClient";
import { ApplicationMetrics, ApplicationMetricMulti, 
  MetricRecord, ModuleMetrics, MultiMetricRecordMulti } from "../services/CloudWatchClient";
import { AppState } from "../store";
import { QueryType, tokenFn } from "../utils";
import { RequestStatus } from "./enums";

export type MetricQuery = {
  startTime: Date | number;
  endTime: Date | number;
  aggrPeriod: number;
}

export interface AppMetricQuery extends MetricQuery {
  applicationIds: string[];
  metricName: string;
};

export interface AppMetricQueryReq extends AppMetricQuery {
  robotId: string;
  deviceId?: string;
  type: QueryType;
};
 
export interface AppMetricsData {
  [index: string]: MetricRecord[];
}

export interface AppMetricsMap {
  [index: string]: {           // RobotId
    metrics: ApplicationMetrics | undefined,
    data: AppMetricsData; // index is robotId
  }
}

// ---------------------------------------------------------

export interface ModuleMetricQuery extends MetricQuery {
  metricName: string;
};

export interface ModuleMetricQueryReq extends ModuleMetricQuery {
  deviceIds: string[];
  sourceId: string;
  sourceType: string;
  type: QueryType;
}

 export interface ModuleMetricsData {
    // QueryType (user, dashboard)
  [index: string]: MetricRecord[]
}

export interface ModuleMetricsMap {
  [index: string]: {          // index is sourceType + sourceId
    metrics: ModuleMetrics | undefined,
    data: ModuleMetricsData
  }
}

// ---------------------------------------------------------

export interface MetricsStateSlice {
  application: AppMetricsMap
  module: ModuleMetricsMap
  status: RequestStatus
  error: string
}

const initialState: MetricsStateSlice = {
  application: {}, 
  module: {},
  status: RequestStatus.idle,
  error: "",
};

const dummyAppMetrics: ApplicationMetrics = {}
const dummyModuleMetrics: ModuleMetrics | undefined = undefined

export const getRobotModuleMetrics = createAsyncThunk(
  "getRobotModuleMetrics",
  async (
    params: {
      sourceId: string;
      sourceType: string;
    },
    thunkAPI
  ) => {
    const client = await CloudWatchClient.getInstance( () => tokenFn(thunkAPI) )
    const response: ModuleMetrics = await client.listModuleMetrics(
      params.sourceId,
      params.sourceType,
    );
    return {
      sourceId: params.sourceId,
      sourceType: params.sourceType,
      metrics: response,
    };
  }
);

export const getRobotModuleMetricsData = createAsyncThunk(
  "getRobotModuleMetricsData",
  async (
    params: ModuleMetricQueryReq,
    thunkAPI
  ) => {
    const client = await CloudWatchClient.getInstance( () => tokenFn(thunkAPI) )
    let query: ModuleMetricQueryReq = {...params}
    let response
    try {
      response = await client.getModuleMetricsData(query);
    }
    catch (err) {
      return (err instanceof Error) ? thunkAPI.rejectWithValue(err.message) : 
       thunkAPI.rejectWithValue(JSON.stringify(err))
    }
    // serialise time
    query.startTime = (query.startTime as Date).getTime()
    query.endTime = (query.endTime as Date).getTime()

    return {
      query: query,
      metrics: response,
    };
  }
);

export const getRobotAppMetricsData = createAsyncThunk(
  "getRobotAppMetricsData",
  async (
    params: AppMetricQueryReq,
    thunkAPI
  ) => {
    const client = await CloudWatchClient.getInstance( () => tokenFn(thunkAPI) )
    
    let query: AppMetricQueryReq = {...params}
    let response
    try {
      response = await client.getAppMetricsData(query)
    }
    catch (err) {
      return (err instanceof Error) ? thunkAPI.rejectWithValue(err.message) : 
       thunkAPI.rejectWithValue(JSON.stringify(err))
    }
    // serialise time
    query.startTime = (query.startTime as Date).getTime()
    query.endTime = (query.endTime as Date).getTime()
    
    return {
      query: query,
      metrics: response,
    };
  }
);

export const getRobotAppMetrics = createAsyncThunk(
  "getRobotAppMetrics",
  async (
    params: {
      robotId: string
      deviceId: string
    },
    thunkAPI
  ) => {
    const client = await CloudWatchClient.getInstance( () => tokenFn(thunkAPI) )
    const response: ApplicationMetrics = await client.listApplicationMetrics(
      params.robotId,
      params.deviceId,
    );
    return {
      robotId: params.robotId,
      metrics: response,
    };
  }
);

export const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // AppMetrics
      .addCase(getRobotAppMetrics.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        const robotId = action.payload.robotId
        if (!state.application[robotId]) {
          state.application[robotId] = {metrics: dummyAppMetrics, data: {}};
        }
        state.application[robotId].metrics = action.payload.metrics
      })
      // ModuleMetrics
      .addCase(getRobotModuleMetrics.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        const key = `${action.payload.sourceType}_${action.payload.sourceId}`
        if (!state.module[key]) {
          state.module[key] = {metrics: dummyModuleMetrics, data: {}};
        }
        state.module[key].metrics = action.payload.metrics
      })
      // AppMetricsData
      .addCase(getRobotAppMetricsData.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getRobotAppMetricsData.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        const robotId = action.payload.query.robotId
        if (!state.application[robotId]) {
          state.application[robotId] = {metrics: dummyAppMetrics, data: {}};
        }
        state.application[robotId].data[action.payload.query.type] = 
          action.payload.metrics.filter((item) => item?.value)
      })
     .addCase(getRobotAppMetricsData.rejected, (state, action) => {
        state.error = action.payload as string
        state.status = RequestStatus.failed
      })
      // ModuleMetricsData
      .addCase(getRobotModuleMetricsData.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getRobotModuleMetricsData.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        const key = `${action.payload.query.sourceType}_${action.payload.query.sourceId}`
        if (!state.module[key]) {
          state.module[key] = {metrics: dummyModuleMetrics, data: {}};
        }
        state.module[key].data[action.payload.query.type] = action.payload.metrics.filter((item) => item?.value)
      })
     .addCase(getRobotModuleMetricsData.rejected, (state, action) => {
        state.error = action.payload as string
        state.status = RequestStatus.failed
      })
  },
});

export const selectLoadingStatus =
  (state: AppState): {status: RequestStatus, error: string} => {
    return {status: state.metrics.status, error: state.metrics.error}
  }

export const selectModuleMetrics =
  (moduleUniqueId: string) =>
  (state: AppState): ModuleMetrics | undefined=> {
    if (state.metrics.module?.[moduleUniqueId]) {
      return state.metrics.module[moduleUniqueId].metrics;
    } else return undefined;
  };

export const selectModuleMetricData =
  (moduleUniqueId: string, type: QueryType) =>
  (state: AppState): MetricRecord[] => {
    if (state.metrics.module?.[moduleUniqueId]?.data[type]) {
      return state.metrics.module[moduleUniqueId].data[type]
    } else return [];
  };

export const selectAppMetrics =
  (robotId: string) =>
  (state: AppState): ApplicationMetrics | undefined=> {
    if (state.metrics.application?.[robotId]) {
      return state.metrics.application[robotId].metrics;
    } else return undefined;
  };

export const selectAppMetricsMulti =
  (metricName: string) =>
  (state: AppState): ApplicationMetricMulti | undefined=> {
    let retval: ApplicationMetricMulti = {}
    Object.keys(state.metrics.application).forEach(robotId => {
      retval[robotId] = state.metrics.application[robotId].metrics?.[metricName]?.applicationIds
    })
    // console.log(retval)
    return retval
  };

export const selectAppMetricData =
  (robotId: string, type: QueryType) =>
  (state: AppState): MetricRecord[] => {
    if (state.metrics.application?.[robotId]?.data[type]) {
      return state.metrics.application[robotId].data[type]
    } else return [];
  };

// Combine mupltiple metrics (multiple robots) to the same timestamps, example:
// m1 = { time:1674914400, value: 1}
// m2 = { time:1674914400, value: 3}
// result = {time:1674914400, m1: 1, m2: 3 }
export const selectAppMetricDataMulti =
  (robotIds: string[], type: QueryType) =>
  (state: AppState): {names: string[], metrics: MultiMetricRecordMulti[]} => {
    // Group samples from multiple metrics to a map with the time as a key
    let timeGrouped: any = {}
    let activeRobots: string[] = []
    robotIds.forEach(robotId => {
      const metricData = state.metrics.application?.[robotId]?.data[type]
      if (metricData !== undefined && metricData.length !== 0) {
        activeRobots.push(robotId)
        metricData.forEach(point => {
          if (!timeGrouped[point?.time ?? 0]) {
            timeGrouped[point?.time ?? 0] = { [robotId]: point?.value }
          } else {
            timeGrouped[point?.time ?? 0][robotId] = point?.value
          }
        })
      }
    })

    let resultMetric: MultiMetricRecordMulti[] = []
    Object.keys(timeGrouped).forEach(time => {
      timeGrouped[time].time = time; resultMetric.push(timeGrouped[time])
    })
    return {names: activeRobots, metrics: resultMetric}
  };

export default metricsSlice.reducer;
