import { Alert, Box, Button, CardMedia, Chip, CircularProgress, Paper, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BaseRoute } from "../../enums";
import { useAppSelector } from "../../hooks";
import { getState } from "../../model/enums";
import { useGetCompanyRobotQuery } from "../../reducers/apiSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import { UNDEFINED_DEVICE_ID, getErrorMessage, iotDeviceSubId } from "../../utils";
import RobotParts from "./RobotParts";

export default function Robot() {
  const { id } = useParams();
  // todo (vca): condition it
  const robotId = id ?? UNDEFINED_DEVICE_ID;

  const navigate = useNavigate();

  const companyId = useAppSelector(selectCompanyId)
  const {
    data: robot = { iotDevice: "no-iot-device", name: "no-name", state: "no-state" },
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCompanyRobotQuery({ companyId: companyId, robotId })

  const handleGoToUtilisation = () => {
    navigate(`/${BaseRoute.DEVICES}/${robotId}/utilisation`);
  };

  const handleGoToEvents = () => {
    navigate(`/${BaseRoute.DEVICES}/${robotId}/events`);
  };

  let content
  if (isLoading) {
    content = <CircularProgress color="primary" />
  } else if (isSuccess) {
    const state = getState(robot.state)
    content = <>
      <Typography variant="h4" sx={{ p: 0, m: 1 }}>
        {robot.name}
      </Typography>
      <Typography variant="body1" sx={{ pb: 1 }}>
        {iotDeviceSubId(robot.iotDevice)}
      </Typography>
      <Chip label={robot.state}
        style={{ color: state.color, backgroundColor: state.background }} />
      <CardMedia
        sx={{ width: "auto" }}
        component="img"
        alt="Robot"
        height="250px"
        image="/robotBoxes.png"
      />
      <Box sx={{ display: "flex", flexWrap: 'wrap', alignItems: 'center',
        justifyContent: 'center', flexDirection: "row", pb: 4
      }}>
        <Button
          sx={{ m: 1, width: "130px", height: "35px" }}
          variant="contained"
          onClick={handleGoToUtilisation}
        >
          Utilisation
        </Button>
        <Button
          sx={{ m: 1, width: "130px", height: "35px" }}
          variant="contained"
          onClick={handleGoToEvents}
        >
          Events
        </Button>
      </Box>
      <RobotParts robotId={robotId} />
    </>
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
      {content}
    </Paper>
  )
}