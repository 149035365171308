import { Box, Button, Typography } from "@mui/material"
import brokenRobot from './broken_robot.png';

export const ErrorFallback = ({ error, resetErrorBoundary }:
  {error: any, resetErrorBoundary: any} ) => {
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Something went wrong... :-O
      </Typography>
      <p>{error.message}</p>
      <img src={brokenRobot} alt='Error'/>
      <Button variant="contained" onClick={resetErrorBoundary}>Try again</Button>
    </Box>
  );
};
