import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useMediaQuery, useTheme } from "@mui/material";
import { getMetricDefinition } from "./Utilisation";
import Dialog from '@mui/material/Dialog';
import { Button, Box, Typography } from "@mui/material";
import CloseFullcreenIcon from '@mui/icons-material/CloseFullscreen';
import { useAppSelector } from "../../hooks";
import { selectCompanyId } from "../../reducers/authSlice";
import { useGetCompanyRobotsQuery } from "../../reducers/apiSlice";

interface ChartProps {
  fromDate: Date
  toDate: Date
  timePeriod: number,
  metricName: string | null
  metricsData: any[]
  metricKeys: string[]
  metricLegend?: {[index:string]: string}
  fullScreen: boolean
  setFullScreen: any
}

const colors = ["#00c5e6", "#f55142", "#f5b342", "#d4f542", "#60f542", 
  "#42f5aa", "#42c5f5", "#4269f5", "#8a42f5", "#d442f5", "#d442f5"]

export default function UtilisationChart({
  fromDate,
  toDate,
  timePeriod,
  metricName,
  metricsData,
  metricKeys,
  metricLegend = {},
  fullScreen,
  setFullScreen
}: ChartProps) {
 
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const minHeight = smUp ? '300px' : '200px'

  const companyId = useAppSelector(selectCompanyId)
  const {
    data: robots = [],
  } = useGetCompanyRobotsQuery(companyId)
 
  const metricDef = getMetricDefinition(metricName)
  const metricsDataProc = metricsData.map(i => (metricDef.normFn(i)))

  // Insert one dummy point of the time distance of the aggregation period 
  // from the start point to ensure the correct bar width calculation.
  if (metricsDataProc.length !== 0) {
    // where the next point should be
    const timeNext = +metricsDataProc[0].time + timePeriod * 60
    // If whe have at least 2 points we will only insert the dummy point th they are
    // too far from each other (more than the time period, but to avoid rounding issue
    // we use 2x time period distance)
    if (metricsDataProc.length >= 2) {
      if (+metricsDataProc[1].time - +metricsDataProc[0].time > 2*timePeriod*60) {
        metricsDataProc.push({ calibrationPoint: 0, time: timeNext })
      }
    } else {
      metricsDataProc.push({ calibrationPoint: 0, time: timeNext })
    }
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      let date = new Date(label * 1000);
      const robot = robots.find(i => i.id === payload[0].name)
      return (
        <div className="custom-tooltip">
          <Typography>
            Date: {date.toLocaleDateString()} {date.getHours() + date.getMinutes()
            + date.getSeconds() === 0 ? '' : date.toLocaleTimeString()}
          </Typography>
          {robot != null &&
            <Typography>
              Device: <b>{robot?.name}</b>
            </Typography>}
          <Typography>
            {metricDef.label}: <b>{payload[0].value}</b>
          </Typography>
        </div>
      );
    }
    return null;
  };

  const calculateTicks = (fromDate: Date, toDate: Date) => {
    const hoursRange = Math.round(
      (toDate.getTime() - fromDate.getTime()) / 1000 / (60 * 60)
    );
    let ticks = [];
    if (hoursRange <= 48) {
      // 1 tick per hour
      for (
        let x = fromDate.getTime() / 1000;
        x <= toDate.getTime() / 1000;
        x = x + 60 * 60
      ) {
        ticks.push(x);
      }
    } else {
      // 1 tick per day
      for (
        let x = fromDate.getTime() / 1000;
        x <= toDate.getTime() / 1000;
        x = x + 60 * 60 * 24
      ) {
        ticks.push(x);
      }
    }

    return ticks;
  };

  const tickFormatter = (unixTime: number) => {
    var t = new Date(unixTime * 1000);
    const hoursRange = Math.round(
      (toDate.getTime() - fromDate.getTime()) / 1000 / (60 * 60)
    );
    if (hoursRange <= 48) {
      const hours = ("0" + t.getHours()).slice(-2);
      const minutes = ("0" + t.getMinutes()).slice(-2);
      return `${hours}:${minutes}`;
    } else {
      const date = ("0" + t.getDate()).slice(-2);
      const month = ("0" + (t.getMonth() + 1)).slice(-2);
      return `${date}/${month}`;
    }
  };

  const legendFormatter = (value: string, entry: any) => {
    const { color } = entry;
    let text = metricLegend[value]
    return <span style={{ color }}>{text}</span>;
  };

  const ticks = calculateTicks(fromDate, toDate);

  const fromDateAdj = Math.floor(fromDate.getTime() / 1000) - 60 * timePeriod / 2
  const toDateAdj = Math.floor(toDate.getTime() / 1000) + 60 * timePeriod / 2

  let chart = (
    <ResponsiveContainer width="100%" minHeight={minHeight}>
      <BarChart data={metricsDataProc} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          tickMargin={25}
          height={50}
          dataKey="time"
          angle={-90} 
          domain={[fromDateAdj, toDateAdj]}
          name="Time"
          minTickGap={1}
          tickFormatter={(unixTime) => tickFormatter(unixTime)}
          ticks={ticks}
          type="number"
          tick = {{ fontSize: "12px" }}
        />
        <Tooltip content={<CustomTooltip />} />
        {smUp && <YAxis tick = {{ fontSize: "12px" }} />}
        {/* <Tooltip formatter={(value: any, name: any, props: any) => ("bbb")}/> */}
        {Object.keys(metricLegend).length !== 0 ? <Legend formatter={legendFormatter}/> : <></>}
        {
          metricKeys.map(key =><Bar key={key} dataKey={key} stackId="main" fill={colors[metricKeys.indexOf(key)]}/> )
        }
      </BarChart>
    </ResponsiveContainer>
  )

  return (
    <>
      {fullScreen === true && (
        <Dialog fullScreen open={true}>
          <Box>
            <Button startIcon={<CloseFullcreenIcon />} onClick={() => setFullScreen(false)}>Close Fullscreen</Button>
          </Box>
          {chart}
        </Dialog>
      ) }
      {fullScreen === false && ( <>{chart}</>) }
    </>
  )
}
