import { Breadcrumbs, Link } from "@mui/material";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BaseRoute, p } from "../enums";
import { selectCompanyId } from "../reducers/authSlice";
import { useAppSelector } from "../hooks";
import { useGetCompanyApplicationsQuery, useGetCompanyRobotsQuery } from "../reducers/apiSlice";
import { noCompany } from "../utils";

const MyBreadcrumbs = () => {

  const companyId = useAppSelector(selectCompanyId);

  const {
    data: robots = []
  } = useGetCompanyRobotsQuery(companyId, { skip: companyId === noCompany.companyId })

  const {
    data: applications = [],
  } = useGetCompanyApplicationsQuery({ companyId }, { skip: companyId === noCompany.companyId })


  const routesRobots = robots.map(robot => {
    return { path: p(BaseRoute.DEVICES) + "/" + robot.id, breadcrumb: robot.name };
  });

  const routesCompanyApps = applications.map(app => {
    return { path: p(BaseRoute.APPLICATIONS) + "/" + app.id, breadcrumb: app.name };
  });

  const breadcrumbs = useBreadcrumbs([...routesRobots, ...routesCompanyApps]);

  return (
    <Breadcrumbs sx={{ p:1 }}
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <Link
          key={index}
          component={RouterLink}
          underline="hover"
          color="inherit"
          to={match.pathname}
        >
          {breadcrumb}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default MyBreadcrumbs;
